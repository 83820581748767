import React from 'react';
import AnimateInView from '@components/Animations/AnimateInView';
import AnimateParallaxOnScroll from '@components/Animations/AnimateParallaxOnScroll';
import {
  SocialFacebook,
  SocialInstagram,
  SocialTiktok,
  SocialTwitter,
  SocialYoutube,
} from '@components/Core/Icons';

type Props = {};

const LandingPage2ParallaxLayer: React.FC<Props> = () => {
  return (
    <AnimateInView
      className="absolute left-0 top-0 right-0 bottom-0 flex flex-col items-center"
      style={{ transform: 'translateY(-70px)' }}
      options={{ threshold: 0.5 }}
    >
      <div className="w-full h-full max-w-1068px relative">
        <AnimateParallaxOnScroll
          speed={-0.3}
          className="absolute left-0 top-0 right-0 bottom-0"
          target={document.querySelector('#landing-overflow-y-auto')}
        >
          <div className="w-full h-full relative" style={{ transform: 'translateY(72vh)' }}>
            <div className="absolute" style={{ left: '10%', top: '10%' }}>
              <div className="w-129px h-129px rounded-12px bg-pink-100 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialInstagram />
              </div>
            </div>
            <div className="absolute" style={{ right: '0', top: '15%' }}>
              <div className="w-24 h-24 rounded-12px bg-primary-200 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialFacebook />
              </div>
            </div>
            <div className="absolute" style={{ left: '13%', bottom: '5%' }}>
              <div className="w-24 h-24 rounded-12px bg-primary-100 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialTwitter />
              </div>
            </div>
            <div className="absolute" style={{ right: '10%', bottom: '5%' }}>
              <div className="w-129px h-129px rounded-12px bg-neutral-200 bg-opacity-20 flex flex-row items-center justify-center">
                <SocialTiktok />
              </div>
            </div>
          </div>
        </AnimateParallaxOnScroll>
        <AnimateParallaxOnScroll speed={-0.1} className="absolute left-0 top-0 right-0 bottom-0">
          <div className="w-full h-full relative" style={{ transform: 'translateY(22vh)' }}>
            <div className="absolute" style={{ right: '40%', top: '10%' }}>
              <div className="w-14 h-14 rounded-12px bg-pink-100 bg-opacity-10" />
            </div>
            <div className="absolute" style={{ left: '0', top: '40%' }}>
              <div className="w-14 h-14 rounded-12px bg-pink-100 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialYoutube />
              </div>
            </div>
            <div className="absolute" style={{ left: '40%', bottom: '15%' }}>
              <div className="w-10 h-10 rounded-12px bg-pink-100 bg-opacity-10" />
            </div>
            <div className="absolute" style={{ right: '5%', bottom: '40%' }}>
              <div className="w-10 h-10 rounded-12px bg-pink-100 bg-opacity-10" />
            </div>
          </div>
        </AnimateParallaxOnScroll>
      </div>
    </AnimateInView>
  );
};

export default LandingPage2ParallaxLayer;
