import React, { useCallback, useState } from 'react';
import { useViewportScroll } from 'framer-motion';
import classnames from 'classnames';
import LandingPage1 from '@components/Landing/LandingPage1';
import LandingPage2 from '@components/Landing/LandingPage2';
import LandingPage3 from '@components/Landing/LandingPage3';
import LandingPage4 from '@components/Landing/LandingPage4';
import LandingPage5 from '@components/Landing/LandingPage5';
import Link from 'next/link';
import { InstagramOutline, SocialFacebookSolid } from '@components/Core/Icons';

type Props = {};

const links = [
  { label: 'About', href: '/about' },
  { label: 'Terms and Conditions', href: '/terms-and-conditions' },
  { label: 'Privacy', href: '/privacy' },
];

const socialAccounts = [
  {
    label: 'Instagram',
    icon: <InstagramOutline className="text-white transform scale-75" />,
    color: 'gradient-instagram',
    href: 'https://www.instagram.com/ambassadr/',
  },
  {
    label: 'Facebook',
    icon: <SocialFacebookSolid className="text-white w-4 h-4" />,
    color: 'facebook',
    href: 'https://www.facebook.com/ambassadr/',
  },
];

const Landing: React.FC<Props> = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [shouldContentBeShown, setShouldContentBeShown] = useState<boolean>(false);

  const onVideoLoaded = useCallback(() => {
    setIsVideoLoaded(true);
  }, []);

  const onVideoPaused = useCallback(() => {
    setShouldContentBeShown(true);
  }, []);
  const onVideoContinued = useCallback(() => {
    // setShouldContentBeShown(false);
  }, []);
  const onVideoEnded = useCallback(() => {
    // setShouldContentBeShown(false);
  }, []);
  const onVideoReset = useCallback(() => {
    // setShouldContentBeShown(false);
  }, []);

  return (
    <div>
      <div className="flex flex-col">
        <div>
          <LandingPage1
            onVideoLoaded={onVideoLoaded}
            onVideoEnded={onVideoEnded}
            onVideoPaused={onVideoPaused}
            onVideoReset={onVideoReset}
            onVideoContinued={onVideoContinued}
          />
        </div>
        {shouldContentBeShown && (
          <>
            <LandingPage2 />
            <LandingPage3 />
            <LandingPage4 />
            <LandingPage5 />
            <footer className="flex flex-col items-center bg-black h-[300px] text-white p-4 lg:p-10">
              <div className="flex flex-row items-center justify-between space-x-4 w-full h-full">
                <div className="flex flex-col space-y-2">
                  <span className="flex flex-row items-center text-3xl font-bebas select-none">
                    Ambassadr
                  </span>
                  <a
                    className="hover:underline"
                    href="mailto:scott@alexandersgrace.com?subject=Contact Us&"
                  >
                    Contact Us
                  </a>
                </div>
                <div className="hidden lg:flex">
                  {links.map((link, index) => (
                    <Link
                      href={link.href}
                      key={index}
                      className="py-3 px-5 text-sm font-poppins hover:underline"
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
                <div className="flex flex-row gap-3 px-4">
                  {socialAccounts.map((element, index) => (
                    <Link
                      href={element.href}
                      key={index}
                      className={`w-8 h-8 flex flex-row items-center justify-center rounded-4px
                    bg-${element.color || 'neutral-500'}
                    text-white`}
                    >
                      {element.icon}
                    </Link>
                  ))}
                </div>
              </div>
              <span className="flex text-xs min-h-[35px] flex-shrink-0 pb-[100px] lg:pb-0 text-center">
                © COPYRIGHT 2023 ALL RIGHTS RESERVED AMBASSADR, ALEXANDERS GRACE LLC
              </span>
            </footer>
          </>
        )}
      </div>
    </div>
  );
};

export default Landing;
