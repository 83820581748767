import React from 'react';
import Landing from '@components/Landing';
import LandingLayout from '@layouts/LandingLayout';
import { GetServerSidePropsContext } from 'next';
import { getSession } from '@auth0/nextjs-auth0';
import connection from '@lib/db/connection';
import { User } from '@lib/db/models/user';

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const session = getSession(ctx.req, ctx.res);

  if (!session) {
    return { props: {} };
  }

  if (!connection.isInitialized) {
    await connection.initialize();
  }

  const { user: authUser } = session!;
  const { sub: auth0Key } = authUser;
  const userRepo = connection.getRepository(User);
  const user = await userRepo.findOne({
    where: { auth0Key },
  });

  if (!user) {
    return {
      redirect: {
        destination: `/auth/signup`,
        permanent: false,
      },
    };
  }

  return {
    redirect: {
      destination: `/dashboard`,
      permanent: false,
    },
  };
};

const IndexPage = () => {
  return (
    <div className="flex-grow">
      <Landing />
    </div>
  );
};

IndexPage.getLayout = (page: any) => {
  return <LandingLayout>{page}</LandingLayout>;
};

export default IndexPage;
