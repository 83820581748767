import React, { useEffect } from 'react';
import AnimateInView from '@components/Animations/AnimateInView';
import Link from 'next/link';
import LandingPage2ParallaxLayer from '@components/Landing/LandingPage2/LandingPage2ParallaxLayer';
import useMobile from '@hooks/useMobile';
import LandingPage2MobileParallaxLayer from '@components/Landing/LandingPage2/LandingPage2MobileParallaxLayer';

const LandingPage2 = () => {
  const { isDesktop } = useMobile();
  return (
    <div>
      <div className="min-h-screen w-screen overflow-hidden flex flex-col items-stretch justify-center px-6 lg:px-40 py-100px">
        <div className="flex-grow flex flex-col items-center justify-center relative">
          {isDesktop && <LandingPage2ParallaxLayer />}
          {!isDesktop && <LandingPage2MobileParallaxLayer />}
          <AnimateInView className="flex flex-col items-center lg:max-w-625px z-20">
            <p className="text-56px leading-none font-barlow-condensed font-semibold text-center cursor-default select-none mb-10">
              Easiest way to approach
              <br />
              social media marketing
            </p>
            <p className="font-poppins text-neutral-400 text-center mb-6">
              Ambassadr allows you to create an army of your most loyal promoters. Run campaigns at
              scale with vetted social media handles who know and have used your products. Have the
              ability to continue engaging with their influencers to form deep and long lasting
              connections.
            </p>
            <Link href="/knowmore#social">
              <span className="font-poppins text-primary-200 cursor-pointer">Learn more</span>
            </Link>
          </AnimateInView>
        </div>
      </div>
    </div>
  );
};

export default LandingPage2;
