import { useEffect, useMemo, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const config = resolveConfig(tailwindConfig);
const lgBreakpoint = config?.theme?.screens?.lg || '1024px';

const useMobile = () => {
  const MQL = window.matchMedia(`(min-width: ${lgBreakpoint})`);
  const [isMobile, setIsMobile] = useState<boolean>(!MQL.matches);
  const isDesktop = useMemo(() => {
    return !isMobile;
  }, [isMobile]);
  useEffect(() => {
    MQL.onchange = e => {
      setIsMobile(!e.matches);
    };
  }, []);
  return { isMobile, isDesktop };
};

export default useMobile;
