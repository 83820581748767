import React, { DOMElement, useCallback, useEffect, useRef, useState } from 'react';
import useScroll from '@hooks/useScroll';
import { motion } from 'framer-motion';

type Props = {
  speed?: number;
  children?: React.ReactNode;
  className?: string;
  style?: Object;
  target?: Window | Element | null;
};

const AnimateParallaxOnScroll: React.FC<Props> = ({
  speed = 0,
  children,
  className,
  style,
  target,
}) => {
  const el = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll({
    target,
  });
  const [y, setY] = useState<number | undefined>();

  const applyParallax = useCallback((scrollY, y, speed) => {
    if (scrollY > y) {
      return (scrollY - y) * speed;
    }
    return 0;
  }, []);
  const handleScroll = useCallback(() => {
    const { current } = el;
    if (current) {
      setY(current.getBoundingClientRect().top);
    }
  }, [el]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      return window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <motion.div
      ref={el}
      className={className}
      initial={{ y: 0 }}
      animate={{
        y: applyParallax(scrollY, y, speed),
      }}
      transition={{ duration: 0.5 }}
      style={style || {}}
    >
      {children}
    </motion.div>
  );
};

export default AnimateParallaxOnScroll;
