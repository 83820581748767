import React from 'react';
import AnimateInView from '@components/Animations/AnimateInView';
import Link from 'next/link';

const LandingPage3 = () => {
  return (
    <div>
      <div className="min-h-screen w-screen overflow-hidden flex flex-col justify-center px-6 lg:px-40 py-100px relative">
        <AnimateInView
          className="absolute bottom-0 lg:top-1/2 right-0 transform lg:-translate-y-1/2 w-full lg:w-1/2 -z-1"
          style={{
            background:
              'transparent radial-gradient(closest-side at 50% 50%, #E3E6F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box',
          }}
        >
          <div className="pb-full" />
        </AnimateInView>
        <div>
          <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:items-center lg:space-x-10">
            <AnimateInView className="w-full max-w-420px flex-shrink-0">
              <div className="flex flex-col">
                <p className="text-5xl lg:text-56px leading-none font-barlow-condensed font-semibold cursor-default select-none mb-10">
                  Engage happy customers once again
                </p>
                <p className="text-sm lg:text-base font-poppins text-neutral-400 mb-6">
                  Ambassadr allows brand managers to go through a campaign workflow wizard to
                  re-engage customers. This could be from simple "Follow Us on Twitter" campaigns to
                  reviewed and sponsored content. Take your stagnant mailing lists into active and
                  engaging brand ambassadors.
                </p>
                <Link href="/knowmore#brands">
                  <span className="font-poppins text-primary-200 cursor-pointer">Learn more</span>
                </Link>
              </div>
            </AnimateInView>
            <AnimateInView
              className="flex-grow flex lg:justify-end"
              variants={{
                visible: {
                  opacity: 1,
                  x: 0,
                },
                hidden: {
                  opacity: 0,
                  x: 200,
                },
              }}
              options={{
                threshold: 0,
              }}
            >
              <div className="flex items-center lg:justify-end -space-x-12 -ml-12 lg:ml-0 lg:-mr-40 transform lg:translate-x-1/5">
                <img
                  className="h-full max-h-50vh lg:min-h-400px"
                  src="/images/landing/brands/bag@2x.png"
                  alt=""
                />
                <img
                  className="h-full max-h-50vh lg:min-h-400px"
                  src="/images/landing/brands/car@2x.png"
                  alt=""
                />
              </div>
            </AnimateInView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage3;
