import React from 'react';
import AnimateInView from '@components/Animations/AnimateInView';
import FormButton from '@components/Core/Forms/FormButton';
import Link from 'next/link';

const LandingPage5 = () => {
  return (
    <div>
      <div className="min-h-screen w-screen overflow-hidden flex flex-col items-center justify-center px-6 lg:px-40 py-100px">
        <AnimateInView>
          <div className="flex flex-row items-center">
            <div className="flex flex-col items-center">
              <p className="text-56px leading-none font-barlow-condensed font-semibold cursor-default select-none mb-10">
                Customized Reward Systems
              </p>
              <p className="text-sm lg:text-base font-poppins text-neutral-400 mb-6 text-center max-w-2xl">
                Our system allows brands to create their own loyalty and reward programs, from
                virtual currency to discount or lotteries of prizes. We are constantly looking
                forward to building more systems, so let us know what is on your mind!
              </p>
              <Link href="/dashboard">
                <FormButton palette="primary-150">Get Started</FormButton>
              </Link>
            </div>
          </div>
        </AnimateInView>
      </div>
    </div>
  );
};

export default LandingPage5;
