module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    // Or if using `src` directory:
    './src/**/*.{js,ts,jsx,tsx}',
  ],
  purge: ['./pages/**/*.{js,ts,jsx,tsx}', './src/**/*.{js,ts,jsx,tsx}'],
  enabled: true,
  mode: 'jit',
  darkMode: false, // or 'media' or 'class',
  safelist: [{ pattern: /(bg|text|border)-./ }],
  theme: {
    extend: {
      fontFamily: {
        baloo: ['"Baloo Bhaina 2"'],
        bebas: ['"Bebas Neue"', 'cursive'],
        poppins: ['"Poppins"', 'sans-serif'],
        barlow: ['"Barlow"', 'sans-serif'],
        'barlow-condensed': ['"Barlow Condensed"', 'sans-serif'],
      },
      colors: {
        level: {
          2: '#737680',
          3: '#A8ABB3',
        },
        divider: {
          0: '#F1EDF7',
        },
        offWhite: '#f8f8f8',
        black: '#11132e',
        neutral: {
          50: '#EFF5F9',
          100: '#F1EDF7',
          150: '#F4F4F6',
          160: '#F2F2F4',
          175: '#D8D8EB',
          180: '#E5E7EB',
          200: '#9d9c9e',
          250: '#9d9c9e',
          300: '#848486',
          350: '#787C84',
          400: '#787C84',
          500: '#6c6b6e',
          550: '#535256',
          600: '#3a3a3e',
          650: '#222126',
          700: '#11132e',
        },
        primary: {
          25: '#E8EEFB',
          50: '#42E4FF',
          75: '#DAF1F9',
          125: '#9ac4f4',
          100: '#83b7f1',
          150: '#6ca9ef',
          200: '#559cec',
          250: '#447cbc',
        },
        status: {
          complete: '#B58B7C',
          denied: '#ED7A7A',
        },
        success: {
          100: '#45B26B',
        },
        warning: {
          50: '#F5C160',
          100: '#FFB930',
          150: '#D59E36',
        },
        pink: {
          100: '#F0839B',
        },
        red: {
          200: '#F0839B',
          400: '#d43539',
        },
        twitter: '#37C8FF',
        facebook: '#39548F',
        discord: '#5865F2',
        inherit: 'inherit',
        tiktok: '#000000',
      },
      borderColor: {
        inherit: 'inherit',
      },
      fontSize: {
        'size-fix': '0',
        '9px': '9px',
        '14px': '14px',
        '32px': '32px',
        '40px': '40px',
        '56px': '56px',
      },
      spacing: {
        '1px': '1px',
        '2px': '2px',
        '3px': '3px',
        '22px': '22px',
        '34px': '34px',
        '54px': '54px',
        '100px': '100px',
        '104px': '104px',
        '120px': '120px',
        '320px': '320px',
      },
      backgroundImage: {
        'gradient-pink-100':
          'radial-gradient(circle, rgba(240,140,179,1) 0%, rgba(241,130,152,1) 100%)',
        'gradient-primary-200': 'radial-gradient(circle, #447cbc 0%, #559cec 100%)',
        'gradient-instagram': 'linear-gradient(200deg, #DC8E3F 0%, #C42D64 49%, #8D1FA7 100%)',
      },
      blur: {
        xs: '2px',
      },
      boxShadow: {
        'pink-100-30': 'rgb(241 130 152 / 30%) 0px 12px 31px',
        'black-3px-6px': '0px 3px 6px #0000000F',
        'black-13px-26px': '0px 13px 26px #0000000F',
        'black-minus7px-26px': '0px -7px 26px #0000000A',
      },
      height: {
        26: '6.5rem',
        '2px': '2px',
        '6px': '6px',
        '14px': '14px',
        '32px': '32px',
        '45px': '45px',
        '52px': '52px',
        '56px': '56px',
        '60px': '60px',
        '72px': '72px',
        '86px': '86px',
        '88px': '88px',
        '90px': '90px',
        '100px': '100px',
        '111px': '111px',
        '120px': '120px',
        '104px': '104px',
        '129px': '129px',
        '140px': '140px',
        '152px': '152px',
        '170px': '170px',
        '178px': '178px',
        '180px': '180px',
        '184px': '184px',
        '208px': '208px',
        '228px': '228px',
        '264px': '264px',
        '301px': '301px',
        '340px': '340px',
        '30vh': '30vh',
        '40vh': '40vh',
      },
      width: {
        '6px': '6px',
        '14px': '14px',
        '44px': '44px',
        '52px': '52px',
        '56px': '56px',
        '60px': '60px',
        '72px': '72px',
        '86px': '86px',
        '90px': '90px',
        '100px': '100px',
        '120px': '120px',
        '129px': '129px',
        '141px': '141px',
        '147px': '147px',
        '180px': '180px',
        '208px': '208px',
        '250px': '250px',
        '264px': '264px',
        '276px': '276px',
        '280px': '280px',
        '300px': '300px',
        '315px': '315px',
        '330px': '330px',
        '360px': '360px',
        '380px': '380px',
        '435px': '435px',
        '600px': '600px',
        '150vw': '150vw',
        '46%': '46%',
        'full-wo-320px': 'calc(100% - 320px)',
      },
      minHeight: {
        '287px': '287px',
        '400px': '400px',
      },
      minWidth: {
        '56px': '56px',
        '62px': '62px',
        '75px': '75px',
        '88px': '88px',
        '120px': '120px',
        '150px': '150px',
        '220px': '220px',
        '280px': '280px',
        '300px': '300px',
        '400px': '400px',
      },
      maxWidth: {
        '50px': '50px',
        '170px': '170px',
        '280px': '280px',
        '364px': '364px',
        '420px': '420px',
        '427px': '427px',
        '433px': '433px',
        '452px': '452px',
        '500px': '500px',
        '625px': '625px',
        '685px': '685px',
        '696px': '696px',
        '757px': '757px',
        '837px': '837px',
        '872px': '872px',
        '876px': '876px',
        '948px': '948px',
        '1068px': '1068px',
        '1/2': '50%',
        fit: 'fit-content',
        'full-wo-320px': 'calc(100% - 320px)',
      },
      scale: {
        '25': '0.25',
        '35': '0.35',
        '250': '2.5',
      },
      maxHeight: {
        '406px': '406px',
        '427px': '427px',
        '50vh': '50vh',
      },
      borderRadius: {
        '2px': '2px',
        '4px': '4px',
        '6px': '6px',
        '8px': '8px',
        '12px': '12px',
        '14px': '14px',
      },
      borderWidth: {
        '1px': '1px',
        '4px': '4px',
      },
      ringWidth: {
        '3': '3px',
      },
      translate: {
        '1/5': '20%',
      },
      padding: {
        full: '100%',
        '8%': '8%',
        '127%': '127%',
      },
      zIndex: {
        '-1': '-1',
        60: '60',
        100: '100',
        110: '110',
        120: '120',
        130: '130',
      },
      animation: {
        enter: 'show .2s forwards',
        leave: 'hide .2s forwards',
      },
      keyframes: {
        show: {
          '0%': { transform: 'scale(0.8) translate(0, -20%)', opacity: '0%' },
        },
        hide: {
          '100%': { transform: 'scale(0.5) translate(0, -40%)', opacity: '0%' },
        },
      },
    },
  },
  variants: {
    extend: {
      padding: ['important'],
      fontSize: ['important'],
      boxShadow: ['important'],
      width: ['important'],
      height: ['important'],
      translate: ['group-hover'],
      borderColor: ['important'],
      zIndex: ['important'],
      space: ['important'],
    },
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    function addImportantVariant({ addVariant }) {
      addVariant('important', ({ container }) => {
        container.walkRules(rule => {
          // eslint-disable-next-line no-param-reassign
          rule.selector = `.\\!${rule.selector.slice(1)}`;
          rule.walkDecls(decl => {
            // eslint-disable-next-line no-param-reassign
            decl.important = true;
          });
        });
      });
    },
  ],
};
