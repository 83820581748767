import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, MotionStyle, Variants } from 'framer-motion';

type Props = {
  children?: React.ReactNode;
  className?: string;
  options?: {
    threshold?: number;
    triggerOnce?: boolean;
    rootMargin?: string;
  };
  variants?: Variants;
  style?: MotionStyle;
};

const AnimateInView: React.FC<Props> = ({
  children,
  className,
  options,
  variants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  },
  style,
}) => {
  const [ref, inView, entry] = useInView({
    threshold: 0.5,
    triggerOnce: false,
    ...options,
  });
  return (
    <motion.div
      ref={ref}
      className={className}
      initial={false}
      animate={inView ? 'visible' : 'hidden'}
      variants={variants}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      style={style}
    >
      {children}
    </motion.div>
  );
};

export default AnimateInView;
