import React from 'react';
import AnimateInView from '@components/Animations/AnimateInView';
import AnimateParallaxOnScroll from '@components/Animations/AnimateParallaxOnScroll';
import {
  SocialFacebook,
  SocialInstagram,
  SocialTiktok,
  SocialTwitter,
  SocialYoutube,
} from '@components/Core/Icons';

type Props = {};

const LandingPage2ParallaxLayer: React.FC<Props> = () => {
  return (
    <AnimateInView
      className="absolute left-0 top-0 right-0 bottom-0 flex flex-col items-center"
      style={{ transform: 'translateY(-70px)' }}
      options={{ threshold: 0.5 }}
    >
      <div className="w-full h-full relative">
        <AnimateParallaxOnScroll
          speed={-0.3}
          className="absolute left-0 top-0 right-0 bottom-0"
          target={document.querySelector('#landing-overflow-y-auto')}
        >
          <div className="w-full h-full relative" style={{ transform: 'translateY(62vh)' }}>
            <div className="absolute" style={{ left: '0', top: '0' }}>
              <div className="w-90px h-90px rounded-12px bg-pink-100 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialInstagram className="transform scale-75" />
              </div>
            </div>
            <div className="absolute" style={{ right: '0', top: '4%' }}>
              <div className="w-16 h-16 rounded-12px bg-primary-200 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialFacebook className="transform scale-75" />
              </div>
            </div>
            <div className="absolute" style={{ left: '3%', bottom: '5%' }}>
              <div className="w-20 h-20 rounded-12px bg-primary-100 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialTwitter className="transform scale-75" />
              </div>
            </div>
            <div className="absolute" style={{ right: '0', bottom: '20%' }}>
              <div className="w-16 h-16 rounded-12px bg-neutral-200 bg-opacity-20 flex flex-row items-center justify-center">
                <SocialTiktok className="transform scale-50 -translate-x-1" />
              </div>
            </div>
          </div>
        </AnimateParallaxOnScroll>
        <AnimateParallaxOnScroll speed={-0.1} className="absolute left-0 top-0 right-0 bottom-0">
          <div className="w-full h-full relative" style={{ transform: 'translateY(22vh)' }}>
            <div className="absolute" style={{ right: '35%', top: '5%' }}>
              <div className="w-8 h-8 rounded-12px bg-pink-100 bg-opacity-10" />
            </div>
            <div className="absolute" style={{ left: '-17%', top: '40%' }}>
              <div className="w-14 h-14 rounded-12px bg-pink-100 bg-opacity-10 flex flex-row items-center justify-center">
                <SocialYoutube />
              </div>
            </div>
            <div className="absolute" style={{ left: '50%', bottom: '5%' }}>
              <div className="w-10 h-10 rounded-12px bg-pink-100 bg-opacity-10" />
            </div>
            <div className="absolute" style={{ right: '-12%', bottom: '53%' }}>
              <div className="w-10 h-10 rounded-12px bg-pink-100 bg-opacity-10" />
            </div>
          </div>
        </AnimateParallaxOnScroll>
      </div>
    </AnimateInView>
  );
};

export default LandingPage2ParallaxLayer;
