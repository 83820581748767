import React from 'react';
import AnimateInView from '@components/Animations/AnimateInView';
import Link from 'next/link';

const LandingPage3 = () => {
  return (
    <div>
      <div className="min-h-screen w-screen overflow-hidden flex flex-col justify-center space-y-6 lg:space-y-0 px-6 lg:px-40 py-100px relative">
        <AnimateInView className="lg:hidden flex-shrink-0 h-40vh self-stretch relative -mx-10 overflow-hidden">
          <div className="absolute top-0 right-0 bottom-0 left-0">
            <img
              className="h-full object-cover object-center"
              src="/images/landing/tablet-opened.png"
              alt=""
            />
          </div>
        </AnimateInView>
        <AnimateInView>
          <div className="flex flex-row items-center max-w-452px">
            <div className="flex flex-col">
              <p className="text-5xl lg:text-56px leading-none font-barlow-condensed font-semibold cursor-default select-none mb-10">
                Analyze your campaign progress
              </p>
              <p className="font-poppins text-neutral-400 mb-6">
                Ambassadr allows brands to create what we call "pools of influence." An aggregation
                of the best followership and engagement within your existing customer base. We allow
                brand managers to view analytical data to improve campaign outreach and conversion.
              </p>
              <Link href="/knowmore#campaigns">
                <span className="font-poppins text-primary-200 cursor-pointer">Learn more</span>
              </Link>
            </div>
          </div>
        </AnimateInView>
        <AnimateInView className="hidden lg:flex absolute left-0 top-0 right-0 bottom-0 flex-row items-stretch justify-end -z-1">
          <div className="max-w-1/2">
            <img
              className="w-full h-full object-contain object-right"
              src="/images/landing/tablet-opened.png"
              alt=""
            />
          </div>
        </AnimateInView>
      </div>
    </div>
  );
};

export default LandingPage3;
