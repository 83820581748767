import React from 'react';
import LandingHeader from '@components/Index/LandingHeader';
import MobileMenu from '@components/Index/MobileMenu';
import classnames from 'classnames';
import { useLandingMenu } from './hooks/useLandingMenu';

type Props = {
  children: React.ReactNode;
};

const LandingLayout: React.FC<Props> = ({ children }) => {
  const { ref: menuRef, shown: menuShown, setShown: setMenuShown } = useLandingMenu();
  return (
    <>
      <div className="flex flex-col overflow-x-hidden">
        <LandingHeader
          className="fixed top-0 bg-white z-30 w-full overflow-hidden"
          menuShown={menuShown}
          onMenuShow={() => setMenuShown(true)}
          onMenuHide={() => setMenuShown(false)}
        />
        <MobileMenu
          ref={menuRef}
          className={classnames({
            'opacity-0 pointer-events-none': !menuShown,
          })}
        />
        {children}
      </div>
    </>
  );
};

export default LandingLayout;
