import { useState, useEffect, useCallback, DOMElement } from 'react';

type State = {
  lastScrollTop: number;
  bodyOffset: DOMRect;
  scrollY: number;
  scrollX: number;
  scrollDirection: string;
};

type Props = {
  target?: Window | Element | null;
};

export const useScroll = ({ target = window }: Props) => {
  const [state, setState] = useState<State>({
    lastScrollTop: 0,
    bodyOffset: document.body.getBoundingClientRect(),
    scrollY: document.body.getBoundingClientRect().top,
    scrollX: document.body.getBoundingClientRect().left,
    scrollDirection: '',
  });
  const handleScrollEvent = useCallback(e => {
    setState(prevState => {
      const prevLastScrollTop = prevState.lastScrollTop;
      const bodyOffset = document.body.getBoundingClientRect();
      return {
        bodyOffset,
        scrollY: -bodyOffset.top,
        scrollX: bodyOffset.left,
        scrollDirection: prevLastScrollTop > -bodyOffset.top ? 'down' : 'up',
        lastScrollTop: -bodyOffset.top,
      };
    });
  }, []);
  useEffect(() => {
    const scrollListener = (e: Event) => {
      handleScrollEvent(e);
    };
    (target || window).addEventListener('scroll', scrollListener);
    return () => {
      (target || window).removeEventListener('scroll', scrollListener);
    };
  }, [handleScrollEvent]);

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection,
  };
};

export default useScroll;
