import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BaselineMousewheel } from '@components/Core/Icons';
import uniq from 'lodash.uniq';
import LandingHeaderAuthButtons from '@components/Index/LandingHeader/LandingHeaderAuthButtons';
import useUser from '@hooks/auth/useUser';
import LandingPageTicker from '@components/Landing/LandingPageTicker';

type Props = {
  onVideoLoaded: () => any;
  onVideoPaused: () => any;
  onVideoReset: () => any;
  onVideoContinued: () => any;
  onVideoEnded: (e: Event) => any;
  autoplay?: boolean;
};

const LandingPage1: React.FC<Props> = ({
  onVideoLoaded,
  onVideoPaused,
  onVideoReset,
  onVideoContinued,
  onVideoEnded,
  autoplay,
}) => {
  const { isAuthenticated } = useUser();

  const video = useRef(null);
  const trigger = useRef(null);
  /**
   * Using ref as mutable object
   * @see https://reactjs.org/docs/hooks-reference.html#useref
   */
  const triggerShownRefBoolean = useRef(false);
  const [triggerInViewRef, triggerInView] = useInView({
    initialInView: true,
  });
  const setTriggerRef = useCallback(
    node => {
      trigger.current = node;
      triggerInViewRef(node);
    },
    [triggerInViewRef],
  );

  const [labelShown, setLabelShown] = useState<boolean>(true);
  const [infoLabelShown, setInfoLabelShown] = useState<boolean>(true);
  const [videoPending, setVideoPending] = useState<boolean>(false);

  useEffect(() => {
    triggerShownRefBoolean.current = triggerInView;
  }, [triggerInView]);

  useEffect(() => {
    if (video?.current) {
      const el = video.current as HTMLVideoElement;
      if (el) {
        el.playbackRate = 2;
        if (autoplay) {
          el.play().then();
        }
      }
    }
  }, [video, autoplay]);
  useEffect(() => {
    if (trigger && video?.current) {
      const el = video.current as HTMLVideoElement;
      if (el) {
        if (!triggerInView) {
          if (el.currentTime === 0) {
            el.currentTime = 0;
            el.play().then(() => {});
          }
        }
        if (triggerInView && el.duration && !videoPending) {
          el.currentTime = el.duration / 2;
          el.play().then(() => {});
          onVideoContinued();
        }
      }
    }
  }, [trigger, triggerInView]);

  const onVideoUpdateListener = useCallback(
    e => {
      const target = e?.target as HTMLVideoElement;
      const { currentTime, duration } = target;
      if (currentTime > 0.1) {
        setInfoLabelShown(false);
      }
      if (currentTime > 0.8) {
        setLabelShown(false);
      }
      if (currentTime > 4.8) {
        setLabelShown(true);
      }
      if (currentTime > 6) {
        setInfoLabelShown(true);
      }
    },
    [labelShown],
  );
  const onVideoUpdatePauseListener = useCallback(
    e => {
      const target = e?.target as HTMLVideoElement;
      const { currentTime, duration } = target;
      if (duration && currentTime >= duration / 2) {
        target.pause();
        onVideoPaused();
        setVideoPending(false);
        target.removeEventListener('timeupdate', onVideoUpdatePauseListener);
      }
    },
    [triggerInView],
  );
  const handleOnVideoEnded = (e: any) => {
    const target = e?.target as HTMLVideoElement;
    target.currentTime = 0;
    target.addEventListener('timeupdate', onVideoUpdatePauseListener);
    onVideoEnded(e);
    setVideoPending(false);
    if (!triggerShownRefBoolean.current) {
      target.play().then();
    }
  };
  const onVideoEndedListener = (e: any) => {
    handleOnVideoEnded(e);
  };
  const onVideoPlayListener = useCallback(() => {
    setVideoPending(true);
  }, [video]);

  useEffect(() => {
    if (video?.current) {
      const el = video.current as HTMLVideoElement;
      if (el) {
        el.addEventListener('loadeddata', onVideoLoaded);
        el.addEventListener('play', onVideoPlayListener);
        el.addEventListener('timeupdate', onVideoUpdateListener);
        el.addEventListener('timeupdate', onVideoUpdatePauseListener);
        el.addEventListener('ended', onVideoEndedListener);
      }
    }
    return () => {
      if (video?.current) {
        const el = video.current as HTMLVideoElement;
        if (el) {
          el.removeEventListener('loadeddata', onVideoLoaded);
          el.removeEventListener('play', onVideoPlayListener);
          el.removeEventListener('timeupdate', onVideoUpdateListener);
          el.removeEventListener('ended', onVideoEndedListener);
        }
      }
    };
  }, [video]);

  return (
    <>
      <AnimatePresence>
        {!infoLabelShown && (
          <motion.div
            className="flex flex-col items-center lg:py-32 fixed left-0 bottom-0 right-0 z-120 lg:hidden lg:pointer-events-none"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { y: 100, opacity: 0 },
              animate: { y: 0, opacity: 1 },
              exit: { y: 100, opacity: 0 },
            }}
            transition={{ duration: 0.4, ease: 'easeOut' }}
          >
            <div className="flex flex-col lg:hidden items-center space-y-4 w-full bg-white border-t shadow-black-minus7px-26px p-4">
              <div className="flex justify-center">
                <LandingHeaderAuthButtons />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className="relative flex flex-col items-center"
        style={{ height: 'calc(100vh + 200px)' }}
      >
        <div
          ref={setTriggerRef}
          className="absolute left-0 right-0 bg-transparent h-px"
          style={{ top: '0' }}
        />
        <div className="sticky top-0 w-full h-screen flex flex-col items-center">
          <AnimatePresence>
            {labelShown && (
              <motion.div
                className="flex flex-col items-center px-10 py-12 lg:py-32 mt-100px absolute left-0 top-0 right-0 z-10"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={{
                  initial: { y: -300, opacity: 0 },
                  animate: { y: 0, opacity: 1 },
                  exit: { y: -300, opacity: 0 },
                }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
              >
                <div className="flex flex-col items-center space-y-4 max-w-872px">
                  <span className="lg:text-xl font-poppins font-medium text-primary-200 cursor-default select-none text-center">
                    Amplify your Word-of-mouth marketing
                  </span>
                  <p className="text-5xl lg:text-7xl font-barlow-condensed font-semibold text-center cursor-default select-none">
                    Connecting brands to the perfect ambassadors
                  </p>
                  <div className="py-2 lg:py-4">
                    <div className="bg-primary-200 text-white rounded-full font-poppins text-xs lg:text-lg font-medium py-1 px-4 lg:px-5 text-center">
                      Automated influencer marketing
                    </div>
                  </div>
                  {!isAuthenticated && (
                    <div className="lg:hidden self-stretch flex justify-center">
                      <div>
                        <LandingHeaderAuthButtons />
                      </div>
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {infoLabelShown && (
              <motion.div
                className="flex flex-col items-center px-10 py-16 absolute left-0 bottom-0 right-0 pointer-events-none z-10"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={{
                  initial: { y: -100, opacity: 0 },
                  animate: { y: 0, opacity: 1 },
                  exit: { y: -100, opacity: 0 },
                }}
                transition={{
                  duration: 0.5,
                  ease: 'easeOut',
                }}
              >
                <div className="flex flex-col items-center space-y-12 max-w-872px">
                  {/*<LandingPageTicker />*/}
                  <BaselineMousewheel />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="h-screen w-screen flex items-center justify-center overflow-hidden">
            <video
              ref={video}
              className="w-150vw lg:w-full h-full lg:object-cover lg:object-bottom max-w-none"
              muted={true}
              playsInline={true}
            >
              <source src="/video/landing-laptop.mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage1;
